.contact {
    float: left;
    width: 70%;
    padding-top: 20px;
}

h1 {
    color: #fff;
    font-size: 65px;
    font-weight: 600;
    text-shadow: 2px 2px 2px #000;
    letter-spacing: 2px;
    text-align: center;
}

.contact > form {
    max-width: 700px;
    margin: 0 auto;
}

.contact > form > .form-group {
    padding: 15px;
    margin-bottom: 0;
}

.contact > form > .form-group > label {
    color: #ffffff;
}

.contact > form > .form-group > input, 
.contact > form > .form-group > textarea {
    font-family:Georgia, 'Times New Roman', Times, serif;
}

.contact > form > .form-group > input {
    padding-top: 5px;
    padding-bottom: 5px;
}

.contact .invalid-feedback {
    font-size: 120% !important;
    text-shadow: 0.5px 0.5px #ffffff;
}

@media (min-width: 768px) {

    .contact > form {
        margin-top: 25px;
    }
}


@media (max-width: 767px) {

    .contact {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 1s ease-in-out;
    }

    .contact > form {
        margin: 0 auto;
    }

    .contact > h1 {
        padding-top: 60px;
    }
    
}