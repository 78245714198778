.main-menu {
  float: right;
  right: 0;
  width: 30%;
  max-width: 350px;
  height: 100%;
}

.menu {
  padding: 10px;
}

.social {
  position: absolute;
  width: 30%;
  bottom: 0;
  right: 0;
  float: right;
  max-width: 350px;
}

.social ul {
  display: table;
  float: right;
}

.social ul li {
  width: 25%;
}

.collapse-menu {
  display: none;
}

@media (max-width: 767px) {
  .main-menu {
    position: absolute;
    height: 50px;
    max-width: 100%;
    width: 100%;
    transition: all 1s;
    top: 0;
    left: 0;
  }

  .menu,
  .social {
    display: none;
    transition: all 0.5s ease-in-out;
  }

  .menu {
    display: none;
  }
}

/**
  MENU COLLAPSE
*/

@media (max-width: 767px) {
  .collapse-menu {
    display: block;
    width: 100%;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 1s ease-in-out;
    background-color: #0000008e;
    z-index: 10;
  }

  .menu-content-collapse {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 18;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }

  .open > .menu-content-collapse {
    width: 100%;
    transition: all 0.5s;
  }

  .open > .menu-collapse-btn {
    /* margin-left: 350px; */
    transition: all 0.5s;
  }

  .menu-content-collapse a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 35px;
    color: #818181;
    display: inline-block;
    transition: 0.3s;
  }

  .menu-content-collapse a:hover {
    color: #f1f1f1;
  }

  .menu-collapse-btn {
    color: white;
    padding: 10px 15px;
    border: none;
  }

  .menu-collapse-btn:hover {
    background-color: #444;
    transition: all 0.5s;
  }

  .close-btn {
    display: none;
  }

  .open .close-btn {
    display: inline;
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 15px;
    padding-right: 15px;
    color: #f5f5f58e;
    font-size: 40px;
    border-style: none;
    background-color: #00000000;
    z-index: 20;
    transition: all 1s;
  }

  .close-btn:hover,
  .close-btn:focus {
    cursor: pointer;
    color: #f5f5f5;
  }

  .open > .menu-social-collapse {
    display: block;
    bottom: 0;
    position: fixed;
    z-index: 20;
    width: 100%;
    padding-bottom: 15px;
    transition: all 2.5s ease-in-out;
  }

  .open > .menu-social-collapse > ul {
    margin: 0 auto;
  }

  .open > .menu-social-collapse > ul > li {
    min-width: 40px;
    width: 10%;
    float: left;
    margin-right: 2px;
  }

  .navbar-title {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    z-index: 5;
    margin: 0 auto;
  }

  .open > .navbar-title {
    display: none;
  }

  .home-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .home-btn > p {
    color: #f1f1f1;
    text-align: center;
    font-family: "Cardinal";
    font-size: 36px;
    padding-top: 10px;
  }
}

button.menu-collapse-btn {
  position: absolute;
  width: 46px;
  height: 100%;
  font-size: 30px;
  text-align: center;
  border-radius: 6px;
  padding: 2px;
  background-color: #0000008e;
  z-index: 5;
}

.navbar-title > a {
  color: #ffffff;
  font-size: 35px;
  font-weight: 600;
  text-decoration: none;
  z-index: 5;
}

.navbar-title > a:hover {
  color: #f5f6f7;
}

.menu-social-collapse {
  display: none;
}
