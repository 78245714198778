.images {
    width: 70%;
    padding-top: 20px;
}


@media (min-width: 767px) {

    .images > h1 {
        font-size: 65px;
    }
    
}

@media (min-width: 360px) and (max-width:767px) {

    .images > h1 {
        font-size: 10vw;
    }
    
}

@media (max-width: 767px) {
    
    .images {
        width: 100%;
    }
    .images > h1 {
        padding-top: 60px;
    }
    
}