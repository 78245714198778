.music {
    float: left;
    width: 70%;
    margin-top: 20px;
}

.music > h1 {
    color: #fff;
    font-size: 65px;
    font-weight: 600;
    text-shadow: 2px 2px 2px #000;
    letter-spacing: 2px;
    text-align: center;
}

@media (max-width: 767px) {

    .music {
        position: absolute;
        width: 100%;
        height: 100%;
        margin: 0;
        left: 0;
        transition: all 1s ease-in-out;
    }

    .music > h1 {
        padding-top: 60px;
    }
    
}